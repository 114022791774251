<template>
  <div class="rencai">
    <div class="rencaitopbox">
      <ul class="rencaitopboxtype">
        <li>专业分类</li>
        <li>全部</li>
        <li
          v-for="(item, index) in typebig"
          :key="index"
          @click="typebigc(item.classid)"
        >
          {{ item.classname }}
        </li>
      </ul>
      <ul class="rencaitopboxtype">
        <li>专业细分</li>
        <li @click="typesmallc('')">全部</li>
        <li
          v-for="(item, index) in typesmall"
          :key="index"
          @click="typesmallc(item.title)"
        >
          {{ item.title }}
        </li>
      </ul>
      <div class="Zhanping_shai">
        <div>
          <el-select v-model="optionsvalue" placeholder="工作状态">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-checkbox v-model="jianlitype">有简历</el-checkbox>
          <el-checkbox v-model="jiangtype">有获奖</el-checkbox>
          <span class="jlshaixuanbttn" @click="liebiao(1)">查询</span>
        </div>
      </div>
    </div>
    <ul class="rencaiulbox">
      <li
        class="rencaiulboxli"
        v-for="(item, index) in rencaiulboxliall"
        :key="item.userid"
      >
        <div class="rencaiulboxli_img">
          <img
            v-lazy="item.userpic"
            alt=""
            @click="rencaiulboxlilink(item.userid)"
          />
          <div v-if="item.job_state == 1" class="rencaiulboxli_img_kj">
            近期可接
          </div>
          <div
            v-if="item.job_state == 2"
            class="rencaiulboxli_img_kj rencaiulboxli_img_kjf"
          >
            暂无时间
          </div>
          <!-- /////////////// -->
          <div
            class="rencaiulboxli_imgbtn rencaiulboxli_imgbtn_yg"
            v-show="item.fensi == 1 && userguanzhutype != item.userid"
            @click="gz(0, item.userid, index)"
          >
            已关注
          </div>
          <div
            class="rencaiulboxli_imgbtn"
            v-show="item.fensi == 0 && userguanzhutype != item.userid"
            @click="gz(1, item.userid, index)"
          >
            关注
          </div>

          <!-- ////////////////// -->
        </div>
        <div class="rencaiulboxli_center">
          <div
            class="rencaiulboxli_center_name"
            @click="rencaiulboxlilink(item.userid)"
          >
            <span>{{ item.username }}</span>
            <icon-svg icon-class="icontrophy" />
            <icon-svg icon-class="iconresume2" />
          </div>
          <div class="rencaiulboxli_center_xin" v-if="false">
            <icon-svg icon-class="iconPraise2" />
            <icon-svg icon-class="iconPraise2" />
            <icon-svg icon-class="iconPraise2" />
            <icon-svg icon-class="iconPraise2" />
            <icon-svg icon-class="iconPraise2" />
          </div>
          <p
            class="rencaiulboxli_center_text"
            @click="rencaiulboxlilink(item.userid)"
          >
            广州 | {{ item.major == null ? "空" : item.major }}
          </p>
          <p
            class="rencaiulboxli_center_text"
            @click="rencaiulboxlilink(item.userid)"
          >
            作品 {{ item.photo_num }} 粉丝 {{ item.fans }}
          </p>
          <p
            class="rencaiulboxli_center_text"
            @click="rencaiulboxlilink(item.userid)"
          >
            {{ item.saytext }}
          </p>
          <ul
            class="rencaiulboxli_center_type"
            @click="rencaiulboxlilink(item.userid)"
          >
            <li
              v-for="(s, index) in item.skill"
              :key="index"
              v-show="index < 2 && s != ''"
            >
              <span v-for="(bq, index) in s" :key="index">{{ bq.text }}</span>
            </li>
            <li v-show="item.skill.length == 1">暂无标签</li>
          </ul>
        </div>
        <div class="rencaiulboxli_ld" v-loading="loading">
          <div
            class="rencaiulboxli_ldecharts"
            :id="'myChart' + item.userid"
          ></div>
        </div>
        <div class="rencaiulboxli_ld rencaiulboxli_ldios" v-loading="loading">
          <div
            class="rencaiulboxli_ldecharts"
            :id="'myChartios' + item.userid"
          ></div>
        </div>
        <ul class="rencaiulboxli_right">
          <li
            class="rencaiulboxli_rightli"
            v-for="(one, index) in item.photo_list"
            :key="one.id"
            v-show="index < 4"
            @click="photo_list(one.id)"
          >
            <img v-lazy="one.titlepic" alt="" />
            <p>{{ one.title }}</p>
          </li>
          <li
            class="rencaiulboxli_rightlizero"
            v-if="item.photo_list.length == 0"
          >
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
      </li>
      <li class="lizero" v-if="lizero">
        <img
          src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
          alt=""
        />
      </li>
    </ul>
    <el-pagination
      pager-count="5"
      @current-change="liebiao"
      :page-size="pagesize"
      background
      :total="total"
      :current-page.sync="pagenum"
    >
    </el-pagination>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".rencaitopboxtype li", function () {
  $(this).css({
    color: "#4FC6FF",
  });
  $(this).siblings("li").css({
    color: "#333",
  });
});

export default {
  components: {},
  data() {
    return {
      userguanzhutype: "0000",
      loading: true,
      userskill: [],
      lizero: false,
      total: 0,
      pagesize: 6,
      pagenum: 1,
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "近期可接",
        },
        {
          value: "2",
          label: "暂无时间",
        },
      ],
      optionsvalue: "",
      rencaiulboxliall: [],
      typebig: [],
      typesmall: [],
      jianlitype: false,
      jiangtype: false,
      major: "",
      winning: "",
      resume: "",
      fensi: [],
      userinfo: {},
      user_info_iostanbqall: [
        {
          text: "规划设计",
          num: "1",
          classred: "",
          ft: "0",
        },
        {
          text: "景观设计",
          num: "2",
          classred: "",
          ft: "0",
        },
        {
          text: "建筑设计",
          num: "3",
          classred: "",
          ft: "0",
        },
        {
          text: "室内设计",
          num: "4",
          classred: "",
          ft: "0",
        },
        {
          text: "软装设计",
          num: "5",
          classred: "",
          ft: "0",
        },
        {
          text: "服装设计",
          num: "6",
          classred: "",
          ft: "0",
        },
        {
          text: "家具设计",
          num: "7",
          classred: "",
          ft: "0",
        },
        {
          text: "交通工具设计",
          num: "8",
          classred: "",
          ft: "0",
        },
        {
          text: "轻工产品设计",
          num: "9",
          classred: "",
          ft: "0",
        },
        {
          text: "机械设备设计",
          num: "10",
          classred: "",
          ft: "0",
        },
        {
          text: "广告设计",
          num: "11",
          classred: "",
          ft: "0",
        },
        {
          text: "包装设计",
          num: "12",
          classred: "",
          ft: "0",
        },
        {
          text: "图书设计",
          num: "13",
          classred: "",
          ft: "0",
        },
        {
          text: "展陈设计",
          num: "14",
          classred: "",
          ft: "0",
        },
        {
          text: "品牌标志设计",
          num: "15",
          classred: "",
          ft: "0",
        },
      ],
    };
  },
  methods: {
    rencaiulboxlilink(id) {
      this.$router.push({ path: "/Rencai_c", query: { data: id } });
    },
    typebigc(id) {
      var that = this;
      this.$axios({
        url: "/api/getClassList",
        method: "post",
        data: {
          class: id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.typesmall = res.data.result;
        } else {
          console.log("请求失败");
        }
      });
    },
    typesmallc(name) {
      this.major = name;
      this.liebiao(1);
    },
    liebiao(page) {
      document.documentElement.scrollTop = 0;
      this.loading = true;
      var that = this;
      that.lizero = true;
      that.rencaiulboxliall = [];
      that.total = 0;
      this.$axios({
        url: "/api/getTalentList",
        method: "post",
        data: {
          page: page,
          limit: that.pagesize,
          major: that.major,
          job_state: that.optionsvalue,
          winning: that.winning,
          resume: that.resume,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          setTimeout(() => {
            that.liebiao2(page);
          }, 1000);
          that.lizero = false;
          that.total = res.data.result.count;
          that.pagenum = page;
          that.rencaiulboxliall = res.data.result.list.map((ree) => {
            if (that.userinfo != null) {
              if (that.fensi.length == 0) {
                ree.fensi = 0;
              } else {
                if (
                  that.fensi.filter((fensi) => {
                    return ree.userid == fensi.userid;
                  }).length > 0
                ) {
                  ree.fensi = "1";
                } else {
                  ree.fensi = "0";
                }
              }
            } else {
              ree.fensi = 0;
            }
            //循环标签
            ree.skill = ree.skill ? ree.skill.split(",").map((re) => {
              return that.user_info_iostanbqall.filter((bq) => {
                return re == bq.num;
              });
            }) : [];
            //循环标签
            return ree;
          });
        } else {
          that.lizero = true;
        }
      });
    },
    liebiao2(page) {
      var that = this;
      this.$axios({
        url: "/api/getTalentList",
        method: "post",
        data: {
          page: page,
          limit: that.pagesize,
          major: that.major,
          job_state: that.optionsvalue,
          winning: that.winning,
          resume: that.resume,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          that.loading = false;
          for (var i in res.data.result.list) {
            var myChartname = "myChart" + res.data.result.list[i].userid + "";
            var width = Number(document.documentElement.clientWidth / 40);
            //雷达图
            console.log(res.data.result.list[i].res_exper);
            var myChart = that.$echarts.init(
              document.getElementById(myChartname)
            );
            // 绘制图表
            myChart.setOption({
              tooltip: {
                trigger: "axis",
              },
              radar: {
                indicator: [
                  { text: "经验", max: 10 },
                  { text: "速度", max: 10 },
                  { text: "活跃", max: 10 },
                  { text: "合作", max: 10 },
                  { text: "技能", max: 10 },
                  { text: "评价", max: 10 },
                ],
                center: ["50%", "50%"],
                radius: width,
                name: {
                  textStyle: {
                    fontSize: 12,
                    color: "#000",
                  },
                },
                splitNumber: 3,
                // shape: "circle",
                shape: "polygon",
                axisLine: {
                  lineStyle: {
                    color: "rgb(64, 158, 255)", //射线的颜色
                  },
                },
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: "#fff", // 图表背景的颜色
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    width: 1,
                    color: "rgb(64, 158, 255)", // 设置网格的颜色
                  },
                },
              },
              series: [
                {
                  type: "radar",
                  tooltip: {
                    trigger: "item",
                  },
                  color: "rgb(64, 158, 255)",
                  itemStyle: { normal: { areaStyle: { type: "default" } } },
                  data: [
                    {
                      value: [
                        res.data.result.list[i].res_exper,
                        res.data.result.list[i].res_speed,
                        res.data.result.list[i].res_active,
                        res.data.result.list[i].res_cooper,
                        res.data.result.list[i].res_skill,
                        res.data.result.list[i].res_evaluate,
                      ],
                      name: "个人资料图",
                      name: "个人资料图",
                    },
                  ],
                },
              ],
            });
            //雷达图
          }
          for (var i = 0; i < res.data.result.list.length; i++) {
            var myChartname =
              "myChartios" + res.data.result.list[i].userid + "";
            var width = Number(document.documentElement.clientWidth / 15);
            //雷达图
            console.log(myChartname);
            console.log(res.data.result.list[i].res_exper);
            var myChart = that.$echarts.init(
              document.getElementById(myChartname)
            );
            // 绘制图表
            myChart.setOption({
              tooltip: {
                trigger: "axis",
              },
              radar: {
                indicator: [
                  { text: "经验", max: 10 },
                  { text: "速度", max: 10 },
                  { text: "活跃", max: 10 },
                  { text: "合作", max: 10 },
                  { text: "技能", max: 10 },
                  { text: "评价", max: 10 },
                ],
                center: ["50%", "50%"],
                radius: width,
                name: {
                  textStyle: {
                    fontSize: 12,
                    color: "#000",
                  },
                },
                splitNumber: 3,
                // shape: "circle",
                shape: "polygon",
                axisLine: {
                  lineStyle: {
                    color: "rgb(64, 158, 255)", //射线的颜色
                  },
                },
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: "#fff", // 图表背景的颜色
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    width: 1,
                    color: "rgb(64, 158, 255)", // 设置网格的颜色
                  },
                },
              },
              series: [
                {
                  type: "radar",
                  tooltip: {
                    trigger: "item",
                  },
                  color: "rgb(64, 158, 255)",
                  itemStyle: { normal: { areaStyle: { type: "default" } } },
                  data: [
                    {
                      value: [
                        res.data.result.list[i].res_exper,
                        res.data.result.list[i].res_speed,
                        res.data.result.list[i].res_active,
                        res.data.result.list[i].res_cooper,
                        res.data.result.list[i].res_skill,
                        res.data.result.list[i].res_evaluate,
                      ],
                      name: "个人资料图",
                      name: "个人资料图",
                    },
                  ],
                },
              ],
            });
            //雷达图
          }
        } else {
          that.lizero = true;
        }
      });
    },
    gz(id, uid, indexid) {
      var that = this;
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
      this.tokentimeyz();
      if (userinfo != null) {
        var gztype = "";
        if (id == "0") {
          gztype = "getCancelFollow";
        } else {
          gztype = "getFollow";
        }
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/" + gztype + "",
          method: "post",
          data: {
            userid: userinfo.userid,
            member_id: uid,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            if (that.rencaiulboxliall[indexid].fensi == 0) {
              that.rencaiulboxliall[indexid].fensi = 1;
              that.$message({
                message: "添加关注成功",
                type: "success",
                duration: 1000,
              });
            } else {
              that.rencaiulboxliall[indexid].fensi = 0;
              that.$message({
                message: "取消关注成功",
                duration: 1000,
              });
            }
            this.$forceUpdate();
            // location.reload();
            console.log(that.rencaiulboxliall);
          } else {
            console.log("请求失败");
          }
        });
      } else {
        this.$router.push({
          path: "/sjlogin",
        });
      }
    },
    photo_list(id) {
      this.$router.push({ path: "/Chengguo_c", query: { data: id } });
    },
  },
  created() {
    this.fenxiang(
      "和作营-设计师",
      "展现个人设计作品、简历便于企业方雇佣。",
      "https://mmbiz.qpic.cn/mmbiz_png/vBjAjicW4DmC6McbmE18LUoeX3ibDAIiawYO72fKWibdSgaqfus1j5WxJR0SN7nOgjNFjGsmk3jPqecG1rqOsUxTzg/0?wx_fmt=png"
    );
    var that = this;
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.userinfo = userinfo;

    if (userinfo != null) {
      that.userguanzhutype = that.userinfo.userid;
      this.$axios({
        //请求粉丝列表
        url: "/api/getFollowFans",
        method: "post",
        data: {
          userid: userinfo.userid,
          f_type: "1",
          page: "1",
          limit: "100000",
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.fensi = res.data.result.list;
        } else {
          console.log("请求失败");
        }
      });
    } else {
      that.userguanzhutype = "00000";
    }
    that.liebiao(1);
    this.$axios({
      //请求大类
      url: "/api/getClassTopList",
      method: "post",
      data: {
        class_sort: "1",
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.typebig = res.data.result;
      } else {
        console.log("请求失败");
      }
    });
  },
  watch: {
    jianlitype: function () {
      if (this.jianlitype) {
        this.resume = "1";
      } else {
        this.resume = "0";
      }
    },
    jiangtype: function () {
      if (this.jiangtype) {
        this.winning = "1";
      } else {
        this.winning = "0";
      }
    },
  },
  mounted() {},
};
</script>
<style>
@import "../assets/css/rencai.css";
</style>

